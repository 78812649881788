<template>
  <div class="body-wide noselect" :class="{ narrow: wParam }">
    <div class="heap heapR">
      <div class="heap-block">
        <PointSelector label="Точка" :id_pointIn="id_point" @change="id_point = $event" disabled="true" />
      </div>
      <div class="heap-block center">
        <input type="checkbox" v-model="showItself" @change="pointListChange(showItself, id)" />
      </div>
      <!-- <div v-if="$root.user.roles.includes('admin')" class="high">
        Auto
        <input type="checkbox" v-model="autoTable" />
      </div> -->
      <!-- <div class="heap-block">
        <div @click.prevent="tableDataRetrieve" class="high pointer">
          <button class="btn" ref="btn">Обновить</button>
        </div>
      </div> -->
    </div>
    <div v-if="loading">
      <Loader />
    </div>

    <!-- <transition name="fade"> -->
    <div v-if="!loading && showItself">
      <ChessItself
        :autoTable="autoTable"
        :tbl="tbl"
        :TURNS="TURNS"
        :id_point="id_point"
        :people="people"
        :jobs="jobs"
        :checks="checks"
        :date_begin="date_begin"
        :id="id"
        :type="'draft'"
        :dataSource="dataSource"
      />
    </div>
    <!-- </transition> -->
  </div>
</template>

<style lang="scss" scoped>
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 1s;
}
.narrow {
  width: 100%;
}
</style>
<style>
th.draftdate {
  width: 5rem;
}
th.draftday {
  width: 1rem;
}
table.nominwidth {
  min-width: 0;
}
</style>

<script>
// import DateSelector from "@/components/DateSelector.vue";
import PointSelector from "@/components/PointSelector.vue";
// import HoursRuler from "@/components/HoursRuler.vue";
import ChessItself from "@/components/ChessItself.vue";
import Loader from "@/components/Loader.vue";
import { dateFormatJS } from "@/components-js/dateFormat";
import { request } from "@/components-js/requestSrv";
import { TURNS_ } from "@/components-js/turns";
// import { datesCheck } from "@/components-js/datesCheck.js";
// import { lS } from "@/components-js/localStorage";
// const loStorage = new lS();

import { HOUR_START, HOUR_END /*, DATE_MAX_INTERVAL*/ } from "@/config/settings";

export default {
  components: {
    // DateSelector,
    PointSelector,
    // HoursRuler,
    Loader,
    ChessItself,
  },

  props: {
    wParam: String,
    date_begin: String,
    date_end: String,
    id_point: Number,
    updateFlag: Number,
    id: Number,
    turnsEmpty: Number,
    dataSource: String,
  },

  data() {
    return {
      autoTable: false,
      loading: true,
      // date_begin: null,
      // date_end: null,
      // DATE_MAX_INTERVAL,
      HOUR_START,
      HOUR_END,
      tbl: [],
      // id_point: null,

      people: [],
      jobs: [],
      TURNS: null,
      checks: [],
      showItself: true,
    };
  },

  created() {
    const hourCondition =
      this.$root?.user && (this.$root?.user?.business === "REBELS" || this.$root?.user?.business === "REBELS ++");
    if (hourCondition) {
      this.HOUR_START = 22;
      this.HOUR_END = 30;
    }
    //console.log(this.$root.user);
    this.TURNS = new TURNS_(this.$root.user.uid);
    this.$root.$on("markPerson", this.markPerson);
  },

  async mounted() {
    // get list of people
    this.people = await request("/api/user/listall", "GET");
    // get list of jobs
    this.jobs = await request("/api/job/list", "GET");

    // set default values for dates and point
    // const d = new Date();
    // this.date_begin = this.date_end = dateFormatJS(d);

    // let tmp = loStorage.getObjectProp(this.$route.path, "date_begin");
    // if (tmp) this.date_begin = tmp;

    // tmp = loStorage.getObjectProp(this.$route.path, "date_end");
    // if (tmp) this.date_end = tmp;

    // tmp = loStorage.getObjectProp(this.$route.path, "id_point");
    // if (tmp) {
    //   this.id_point = tmp;
    this.tableDataRetrieve();
    // }
  },

  beforeDestroy() {
    this.TURNS.clear();
    this.$root.$off("markPerson", this.markPerson);
  },

  watch: {
    updateFlag: function() {
      this.tableDataRetrieve();
    },
  },

  methods: {
    pointListChange(showItself, id) {
      this.$emit("pointListChange", { flag: showItself, id: id });
    },

    markPerson(id_people, color) {
      this.TURNS.highlightGroupByUser(id_people, color);
    },

    // getting data from server
    async tableDataRetrieve() {
      this.loading = true;

      // const res = datesCheck(this.date_begin, this.date_end, DATE_MAX_INTERVAL);
      // this.date_begin = dateFormatJS(res.date_begin);
      // this.date_end = dateFormatJS(res.date_end);

      // DB request
      let q = "/api/turndraft/list";
      // if (this.autoTable) q = "/api/turn/listauto";

      // const checksTemp = await request("/api/sales/salesget", "POST", {
      //   id_point: this.id_point,
      //   date_begin: this.date_begin,
      //   date_end: this.date_end,
      // });
      // this.checks = [];
      // for (const el of checksTemp) {
      //   if (this.checks[el.date] == undefined) this.checks[el.date] = [];
      //   this.checks[el.date][el.hour] = el.sales;
      // }
      // checksTemp.length = 0;

      const data = await request(
        q,
        "POST",
        {
          id_point: this.id_point,
          date_begin: this.date_begin,
          date_end: this.date_end,
          turnsEmpty: this.turnsEmpty,
          dataSource: this.dataSource,
        },
        this.$route.path
      );

      // even if data array is empty, still need to show empty table

      // temporary array
      let tbl_copy = [];

      // from date_begin to date_end
      let d1 = new Date(this.date_begin);
      const d2 = new Date(this.date_end);

      let orderAbs = 0;
      let orderMax = -1;
      while (d1 <= d2) {
        // row order in a turn
        let turn_order = 0;
        let date_current = dateFormatJS(d1);
        // if there is a TURN at the date
        if (data.findIndex(item => item.turn_date === date_current) > -1) {
          // found TURN at the date
          // remember row, where to start fill array
          let length_current = tbl_copy.length;
          orderMax = 0;
          // through all TURN array, for every row in dates array
          for (let X of data.filter(item => item.turn_date === date_current)) {
            // get order in a turn from turn record
            turn_order = X.turn_order;

            // create new row in tbl, if not exist
            if (tbl_copy[length_current + turn_order] === undefined) {
              // fix max order
              orderMax = turn_order;
              // if we have empty row(s)
              for (let l = length_current; l <= length_current + turn_order; l++) {
                if (tbl_copy[l] === undefined) {
                  tbl_copy[l] = [];
                  for (let i = this.HOUR_START; i <= this.HOUR_END; i++) tbl_copy[l][i] = 0;
                  // prelast column is date
                  tbl_copy[l].push(date_current);
                  // last column is turn_order
                  tbl_copy[l].push(l - length_current);
                }
              }
            }

            // through hours
            for (let k = X.hour_begin; k <= X.hour_end; k++)
              // insert turn
              tbl_copy[length_current + turn_order][k] = X.id_turn;

            X.orderAbs = orderAbs + turn_order;
          }
          orderAbs += orderMax;
          orderAbs++;
        }

        for (let i = orderMax + 1; i < this.turnsEmpty; i++) {
          // TURN is absent at the date, fill it with zero values
          let date_current = dateFormatJS(d1);
          // add row into tbl array
          tbl_copy[tbl_copy.length] = [];
          // increase order
          orderAbs++;
          // fill row of tbl array with zero values
          for (let k = this.HOUR_START; k <= this.HOUR_END; k++) tbl_copy[tbl_copy.length - 1][k] = 0;
          // prerlast column is date
          tbl_copy[tbl_copy.length - 1].push(date_current);
          // last column is turn_order
          tbl_copy[tbl_copy.length - 1].push(i);
        }

        d1 = new Date(d1.setDate(d1.getDate() + 1));
        orderMax = -1;
      }
      this.TURNS.fill(data);
      this.tbl = tbl_copy;
      this.loading = false;
    },
  },
};
</script>
